"use strict";
exports.__esModule = true;
var core_http_1 = require("@azure/core-http");
var defaultSettings = {
    version: "1.0.0",
    registrationId: "",
    sessionId: "",
    pnhAppId: "AcsWeb",
    pnhTemplate: "AcsWeb_Chat_1.2",
    platform: "SPOOL",
    platformUIVersion: "0.0.0",
    environment: "",
    productName: "acs-chat-web",
    trouterServiceUrl: "https://go.trouter.skype.com/v4/a",
    registrarServiceUrl: "https://edge.skype.com/registrar/prod/v3/registrations",
    registrarRefreshTimeoutInMs: 350000,
    timeoutOptions: {
        connectionTimeoutMs: 20000,
        fetchTimeoutMs: 10000,
        pingTimeoutMs: 40000,
        pongTimeoutMs: 5000,
        maxBackoffMs: 50000,
        requestTimeoutMs: 5000
    },
    maxRegistrationTimeInMs: 7200000
};
exports.createSettings = function (options) {
    var _a;
    var settings = defaultSettings;
    settings.registrationId = core_http_1.generateUuid();
    settings.sessionId = core_http_1.generateUuid();
    settings.maxRegistrationTimeInMs = (_a = options === null || options === void 0 ? void 0 : options.registrationTimeInMs) !== null && _a !== void 0 ? _a : defaultSettings.maxRegistrationTimeInMs;
    return settings;
};
exports.defaultTelemetrySettings = {
    // TBD Can we hook up OpenTelemetry?
    enabled: false
};
